var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"layoutContent"},[_c('k-alert-msg',{ref:"lblMsg"}),_c('KValidationObserver',{ref:"observer"},[_c('k-form',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.isPrevent.apply(null, arguments)},"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"layoutCard"},[_c('div',{staticClass:"cardBody",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"cardHead"},[_vm._v(" "+_vm._s(_vm.$g("personalData"))+" ")]),(_vm.isFieldShow(_vm.workerFieldNameEnums.photo))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.photo),
              }),attrs:{"id":"photoImg","label-suffix":"photo","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.photo)
                  ? 'require-mark'
                  : ''}},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between"},[_c('div',{staticClass:"mr-2"},[_c('k-img',{staticStyle:{"max-height":"100px"},attrs:{"src":_vm.form.photoPath}})],1)])]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.jobCode))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.jobCode),
              }),attrs:{"label-suffix":"jobCode","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isJobCodeRequire ? 'require-mark' : ''}},[_c('k-form-input',{attrs:{"plaintext":true},model:{value:(_vm.form.jobCode),callback:function ($$v) {_vm.$set(_vm.form, "jobCode", $$v)},expression:"form.jobCode"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.firstName))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.firstName),
              }),attrs:{"id":"firstNameInput","label-suffix":"firstName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.firstName)
                  ? 'require-mark'
                  : ''}},[_c('k-form-input',{attrs:{"id":"firstName","plaintext":true,"aria-label":_vm.$g('firstName')},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.middleName))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.middleName),
              }),attrs:{"id":"middleNameInput","label-suffix":"middleName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.middleName)
                  ? 'require-mark'
                  : ''}},[_c('k-form-input',{attrs:{"id":"middleName","aria-label":_vm.$g('middleName'),"plaintext":true},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.lastName))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.lastName),
              }),attrs:{"id":"lastNameInput","label-suffix":"lastName","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.lastName)
                  ? 'require-mark'
                  : ''}},[_c('k-form-input',{attrs:{"id":"lastName","aria-label":_vm.$g('lastName'),"plaintext":true},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.suffix))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.suffix),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.suffix)
                  ? 'require-mark'
                  : '',"label-suffix":"Suffix","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-select',{attrs:{"plaintext":true,"options":_vm.suffixOptions},model:{value:(_vm.form.suffix),callback:function ($$v) {_vm.$set(_vm.form, "suffix", $$v)},expression:"form.suffix"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.dob))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.dob),
              }),attrs:{"id":"dobDatepicker","label-suffix":"dob","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.dob)
                  ? 'require-mark'
                  : ''}},[_c('k-form-datepicker',{attrs:{"plaintext":true,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.dob)},model:{value:(_vm.form.dob),callback:function ($$v) {_vm.$set(_vm.form, "dob", $$v)},expression:"form.dob"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.yob))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.yob),
              }),attrs:{"id":"yobDatepicker","label-suffix":"yearOfBirth","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.yob)
                  ? 'require-mark'
                  : ''}},[_c('k-form-select',{attrs:{"options":_vm.dobOptions,"plaintext":true,"tab-index":_vm.getOrderIndex(_vm.workerFieldNameEnums.yob)},model:{value:(_vm.form.yob),callback:function ($$v) {_vm.$set(_vm.form, "yob", $$v)},expression:"form.yob"}})],1):_vm._e(),(
                _vm.showElectionInspector &&
                _vm.isFieldShow(_vm.workerFieldNameEnums.electionInspector)
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.electionInspector),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.electionInspector)
                  ? 'require-mark'
                  : '',"label-suffix":"electionInspector","label-for":"electionInspector","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-select',{attrs:{"plaintext":true,"options":_vm.yesNoOptions},model:{value:(_vm.form.electionInspector),callback:function ($$v) {_vm.$set(_vm.form, "electionInspector", $$v)},expression:"form.electionInspector"}})],1):_vm._e(),(_vm.isShowPartyId)?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.partyId),
              }),attrs:{"id":"partySelect","label-suffix":"party","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind","label-class":_vm.politicalPartyRequired || _vm.form.electionInspector == 2
                  ? 'require-mark'
                  : ''}},[_c('k-form-select',{attrs:{"options":_vm.partys,"plaintext":true},model:{value:(_vm.form.politicalPartyId),callback:function ($$v) {_vm.$set(_vm.form, "politicalPartyId", $$v)},expression:"form.politicalPartyId"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.ssn))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.ssn),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.ssn)
                  ? 'require-mark'
                  : '',"label-suffix":"sSN","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","id":"ssnTooltip"}},[_c('b-input-group',{staticClass:"align-items-center"},[_c('div',{staticClass:"col-form-label",staticStyle:{"min-width":"120px","user-select":"none"}},[_vm._v(" "+_vm._s(_vm.isShowPassword ? _vm.showSSN : "* * * * * * * * *")+" ")]),_c('b-icon',{staticClass:"iconEye",attrs:{"icon":_vm.isShowPassword ? 'eye-fill' : 'eye-slash-fill'},on:{"click":function($event){_vm.isShowPassword = !_vm.isShowPassword}}})],1)],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.raceId))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.raceId),
              }),attrs:{"id":"raceSelect","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.raceId)
                  ? 'require-mark'
                  : '',"label-suffix":"whatsYourRace","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-for":"noneBind"}},[_c('k-form-select',{attrs:{"options":_vm.raceOptions,"plaintext":true},model:{value:(_vm.form.raceId),callback:function ($$v) {_vm.$set(_vm.form, "raceId", $$v)},expression:"form.raceId"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('div',{staticClass:"cardHead",style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.residentialAddress),
              })},[_vm._v(" "+_vm._s(_vm.$g("residentialAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"residentialAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("residentialAddressDescription"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.residentialAddress))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.residentialAddress),
              }),attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('address-view',{staticClass:"cardBody",attrs:{"id":"residentialAddress","address":_vm.residentialAddressForm,"addressformat":_vm.addressformat,"address-type":1}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('div',{staticClass:"cardHead",style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
              })},[_vm._v(" "+_vm._s(_vm.$g("postalAddress"))+" "),(_vm.needAddressDescription)?_c('div',{staticClass:"addressDescription",attrs:{"id":"postalAddressDiv"}},[_vm._v(" "+_vm._s(_vm.$g("postalAddressDescription1"))+" "+_vm._s(_vm.$g("postalAddressDescription2"))+" ")]):_vm._e()]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.postalAddress))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.postalAddress),
              }),attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('address-view',{staticClass:"cardBody",attrs:{"id":"postalAddress","address":_vm.postalAddressForm,"addressformat":_vm.addressformat,"address-to-copy":_vm.residentialAddressForm,"address-type":2,"is-copy":_vm.form.isSameAsResidentialAddress}})],1):_vm._e(),(!_vm.isHideStudentFields)?_c('div',{staticClass:"cardHead",style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
              })},[_vm._v(" "+_vm._s(_vm.$g("applicationDetails"))+" ")]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.languageIdList))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageIdList),
              }),attrs:{"id":"languageSpokenMultiselect","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.languageIdList)
                  ? 'require-mark'
                  : '',"label-suffix":"languageSpoken","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-multiselect',{attrs:{"options":_vm.languageList,"plaintext":true},model:{value:(_vm.form.languageIdList),callback:function ($$v) {_vm.$set(_vm.form, "languageIdList", $$v)},expression:"form.languageIdList"}})],1):_vm._e(),(
                _vm.isFieldShow(_vm.workerFieldNameEnums.workTravelId) &&
                !_vm.isHideTravel
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workTravelId),
              }),attrs:{"id":"travelSelect","label-suffix":_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'travelSelect',
                  'vIf'
                )
                  ? 'travel'
                  : '',"label":_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'travelSelect',
                  'vIf'
                )
                  ? 'travel'
                  : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workTravelId)
                  ? 'require-mark'
                  : ''}},[_c('k-form-select',{attrs:{"options":_vm.travelList,"plaintext":true},model:{value:(_vm.form.workTravelId),callback:function ($$v) {_vm.$set(_vm.form, "workTravelId", $$v)},expression:"form.workTravelId"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.asWorkerPastThreeYear))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(
                  _vm.workerFieldNameEnums.asWorkerPastThreeYear
                ),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.asWorkerPastThreeYear)
                  ? 'require-mark'
                  : '',"label-suffix":"pastThreeYears","id":"pastThreeYearsRadio","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('b-form-radio-group',{staticClass:"mt-lg-2",attrs:{"disabled":true,"options":_vm.yesOrNoOptions},model:{value:(_vm.form.asWorkerPastThreeYear),callback:function ($$v) {_vm.$set(_vm.form, "asWorkerPastThreeYear", $$v)},expression:"form.asWorkerPastThreeYear"}})],1):_vm._e(),(
                !_vm.isHideStudentFields &&
                _vm.isFieldShow(_vm.workerFieldNameEnums.jobIdList)
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.jobIdList),
              }),attrs:{"id":"positionPreferenceCheckbox","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.jobIdList)
                  ? 'require-mark'
                  : '',"label-suffix":"positionPreference","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-checkbox-group',{staticClass:"col-form-label d-block",attrs:{"options":_vm.positionPreferenceList,"plaintext":true,"stacked":""},model:{value:(_vm.form.jobIdList),callback:function ($$v) {_vm.$set(_vm.form, "jobIdList", $$v)},expression:"form.jobIdList"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.volunteer))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.volunteer),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.volunteer)
                  ? 'require-mark'
                  : '',"id":"volunteerRadio","label-suffix":"volunteer","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"disabled":true,"options":_vm.yesOrNoOptions},model:{value:(_vm.form.volunteer),callback:function ($$v) {_vm.$set(_vm.form, "volunteer", $$v)},expression:"form.volunteer"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.municipalityBoundaryIds))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(
                  _vm.workerFieldNameEnums.municipalityBoundaryIds
                ),
              }),attrs:{"id":"municipalityMultiselect","label-suffix":"Municipality","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.municipalityBoundaryIds)
                  ? 'require-mark'
                  : ''}},[_c('k-multiselect',{attrs:{"options":_vm.municipalityBoundaryList,"plaintext":true},model:{value:(_vm.form.municipalityBoundaryIds),callback:function ($$v) {_vm.$set(_vm.form, "municipalityBoundaryIds", $$v)},expression:"form.municipalityBoundaryIds"}})],1):_vm._e(),(
                _vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId) &&
                !_vm.isHideStudentFields
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId),
              }),attrs:{"id":"hearAboutSelect","label-suffix":!_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'hearAboutSelect',
                  'vIf'
                )
                  ? 'hearAbout'
                  : '',"label":_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'hearAboutSelect',
                  'vIf'
                )
                  ? 'hearAbout'
                  : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7","label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerHearAboutId)
                  ? 'require-mark'
                  : ''}},[_c('k-form-select',{attrs:{"options":_vm.hearAbouts,"plaintext":true},model:{value:(_vm.form.workerHearAboutId),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutId", $$v)},expression:"form.workerHearAboutId"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.workerHearAboutId))?_c('k-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHearAboutText),expression:"showHearAboutText"}],style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerHearAboutId),
              }),attrs:{"id":"otherInput","label":_vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'otherInput',
                  'vIf'
                )
                  ? 'other'
                  : '',"label-class":_vm.showHearAboutText ? 'require-mark' : '',"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-input',{attrs:{"plaintext":true},model:{value:(_vm.form.workerHearAboutText),callback:function ($$v) {_vm.$set(_vm.form, "workerHearAboutText", $$v)},expression:"form.workerHearAboutText"}})],1):_vm._e(),(
                _vm.$route.query.needWorkerTypeDocument == 1 &&
                _vm.isFieldShow(_vm.workerFieldNameEnums.workerTypeUpload)
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.workerTypeUpload),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.workerTypeUpload)
                  ? 'require-mark'
                  : '',"label-suffix":_vm.$route.query.workerTypeDocumentLabel,"label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},_vm._l((_vm.form.workerTypeDocument),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),1):_vm._e(),(
                _vm.isFieldShow(_vm.workerFieldNameEnums.hadESALanguageProficiency)
              )?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(
                  _vm.workerFieldNameEnums.hadESALanguageProficiency
                ),
              }),attrs:{"label-class":_vm.isFieldRequired(
                  _vm.workerFieldNameEnums.hadESALanguageProficiency
                )
                  ? 'require-mark'
                  : '',"id":"hasCertificationUploadedRadio","label-suffix":"haveLanguageProficiencyCertified","label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('k-form-radio-group',{staticClass:"col-form-label d-block",attrs:{"disabled":true,"options":_vm.yesOrNoOptions},model:{value:(_vm.form.hadESALanguageProficiency),callback:function ($$v) {_vm.$set(_vm.form, "hadESALanguageProficiency", $$v)},expression:"form.hadESALanguageProficiency"}})],1):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.certifiedBy))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.certifiedBy),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.certifiedBy)
                  ? 'require-mark'
                  : '',"id":"certifiedByText","label-suffix":"certificate","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},_vm._l((_vm.form.certificateDocument),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),1):_vm._e(),_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"workerPreferenceExtFields",attrs:{"plaintext":true},model:{value:(_vm.form.workerPreferenceExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerPreferenceExtFields", $$v)},expression:"form.workerPreferenceExtFields"}}),(
                _vm.getConfigVal(
                  _vm.curPageConfigAndDefaultPageConfig,
                  'extendFields',
                  'vIf'
                )
              )?_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],ref:"extFields",attrs:{"id":"extendFields","plaintext":true},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}}):_vm._e(),_c('k-extend-fields',{directives:[{name:"frag",rawName:"v-frag"}],attrs:{"plaintext":true},model:{value:(_vm.form.workerTypeExtFields),callback:function ($$v) {_vm.$set(_vm.form, "workerTypeExtFields", $$v)},expression:"form.workerTypeExtFields"}}),(_vm.isFieldShow(_vm.workerFieldNameEnums.citizenshipDocuments))?_c('div',{staticClass:"content-title",style:({
                order: _vm.getOrderIndex(
                  _vm.workerFieldNameEnums.citizenshipDocuments
                ),
              })},[_vm._v(" "+_vm._s(_vm.$g( _vm.getConfigVal( _vm.curPageConfigAndDefaultPageConfig, "citizenshipDocumentUpload", "title" ) ))+" ")]):_vm._e(),(_vm.isFieldShow(_vm.workerFieldNameEnums.citizenshipDocuments))?_c('k-form-group',{style:({
                order: _vm.getOrderIndex(
                  _vm.workerFieldNameEnums.citizenshipDocuments
                ),
              }),attrs:{"label-class":_vm.isFieldRequired(_vm.workerFieldNameEnums.citizenshipDocuments)
                  ? 'require-mark'
                  : '',"label-suffix":"document.file","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},_vm._l((_vm.form.citizenshipDocuments),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])}),1):_vm._e(),_c('div',{staticClass:"cardHead",style:({
                order: _vm.getOrderIndex(_vm.workerFieldNameEnums.languageId),
              })},[_vm._v(" "+_vm._s(_vm.$g("notificationPreferences"))+" ")]),_c('NotificationPreferences',{directives:[{name:"frag",rawName:"v-frag"}],staticClass:"cardBody",model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),(
              _vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'termsAndConditions',
                'vIf'
              )
            )?_c('div',{staticClass:"cardHead",attrs:{"id":"termsAndConditions"}},[_vm._v(" "+_vm._s(_vm.$g("termsAndConditions"))+" ")]):_vm._e(),(
              _vm.getConfigVal(
                _vm.curPageConfigAndDefaultPageConfig,
                'agreeContentCheckbox',
                'vIf'
              )
            )?_c('div',{staticClass:"cardBody",attrs:{"id":"agreeContentCheckbox"}},[_c('k-form-group',{attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('div',{staticClass:"mb-3",domProps:{"innerHTML":_vm._s(_vm.desc)}}),_c('k-form-checkbox',{attrs:{"disabled":true},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(" agreeContent ")])],1)],1):_vm._e()]),(_vm.reCaptchaVif)?_c('k-form-group',{attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[(_vm.isManMachine)?_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$g("validate.required"))+" ")]):_vm._e(),_c('ReCaptcha',{staticStyle:{"margin-bottom":"25px"},on:{"validate":_vm.validate,"expired":_vm.resetCaptcha}})],1):_vm._e(),_c('k-form-group',{staticClass:"wapBtnCenter",attrs:{"label-for":"noneBind","label-align-md":"right","label-cols-md":"3","content-cols-lg":"6","content-cols-md":"7"}},[_c('div',{staticStyle:{"margin-top":"30px"}},[_c('k-button',{staticClass:"w-150",staticStyle:{"margin-right":"80px"},attrs:{"variant":"utility"},on:{"click":_vm.backAction,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.backAction.apply(null, arguments)}}},[_vm._v(" back ")]),_c('k-button',{staticClass:"w-150",attrs:{"variant":"primary"},on:{"click":_vm.submit,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}}},[_vm._v(" submit ")])],1)])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }