<template>
  <TimeLine :cur-index="curIndex" id="timeLine">
    <template #topModule>
      <TopInfo
        other-i-d="topInfo"
        :id="$route.query.id"
        :page-name="$route.query.pagename"
        :title="topTitle"
        :sub-title="$route.query.title"
        ref="topInfo"
      />
    </template>
    <template>
      <div v-if="curIndex === 0">
        <k-alert-msg ref="lblMsg" />
        <KValidationObserver ref="observer">
          <k-form
            @keydown.enter="isPrevent"
            @submit.prevent="save"
            :disabled="isDisable"
          >
            <div class="layoutCard">
              <div
                class="cardBody"
                style="display: flex; flex-direction: column"
              >
                <div class="cardHead">
                  {{ $g("personalData") }}
                </div>
                <k-form-group
                  id="photoImg"
                  label-suffix="photo"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.photo),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.photo)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.photo)"
                >
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="mr-2">
                      <k-img :src="form.photoPath" style="max-height: 100px" />
                    </div>
                    <div class="d-flex flex-wrap align-items-start mt-2">
                      <k-button
                        @click="changePhoto"
                        class="mb-1 mr-2"
                        v-if="!isFieldViewOnly(workerFieldNameEnums.photo)"
                      >
                        buttons.upload
                      </k-button>
                      <k-button
                        @click="removePhoto"
                        variant="utility"
                        class="mb-1"
                        v-if="!isFieldViewOnly(workerFieldNameEnums.photo)"
                      >
                        buttons.remove
                      </k-button>
                    </div>
                  </div>
                </k-form-group>
                <k-form-group
                  label-suffix="jobCode"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.jobCode)
                      ? 'require-mark'
                      : ''
                  "
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.jobCode),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.jobCode)"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.jobCode),
                      max: 200,
                      remote: isJobCodeUsed,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      v-model="form.jobCode"
                      :plaintext="
                        jobCodeAuto ||
                        isFieldViewOnly(workerFieldNameEnums.jobCode)
                      "
                      :tab-index="getOrderIndex(workerFieldNameEnums.jobCode)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="firstNameInput"
                  label-suffix="firstName"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.firstName),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.firstName)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.firstName)"
                >
                  <KValidationProvider
                    name="firstName"
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.firstName),
                      max: 200,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="firstName"
                      v-model="form.firstName"
                      :aria-label="$g('firstName')"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.firstName)
                      "
                      :tab-index="getOrderIndex(workerFieldNameEnums.firstName)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="middleNameInput"
                  label-suffix="middleName"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.middleName),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.middleName)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.middleName)"
                >
                  <KValidationProvider
                    name="middleName"
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.middleName
                      ),
                      max: 200,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="middleName"
                      v-model="form.middleName"
                      :aria-label="$g('middleName')"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.middleName)
                      "
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.middleName)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="lastNameInput"
                  label-suffix="lastName"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.lastName),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.lastName)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.lastName)"
                >
                  <KValidationProvider
                    name="lastName"
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.lastName),
                      max: 200,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="lastName"
                      v-model="form.lastName"
                      :aria-label="$g('lastName')"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.lastName)
                      "
                      :tab-index="getOrderIndex(workerFieldNameEnums.lastName)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.suffix),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.suffix)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.suffix)"
                  id="suffixInput"
                  label-suffix="Suffix"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.suffix),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.suffix"
                      :options="suffixOptions"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.suffix)"
                      :tab-index="getOrderIndex(workerFieldNameEnums.suffix)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="dobDatepicker"
                  label-suffix="dob"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  label-for="noneBind"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.dob),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.dob)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.dob)"
                >
                  <KValidationProvider
                    name="dob"
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.dob),
                      validateDateFormat: true,
                      validateDateIsRequired: isFieldRequired(
                        workerFieldNameEnums.dob
                      ),
                      remote: checkAge,
                      dob: true,
                    }"
                    v-slot="{ errors }"
                    slim
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-datepicker
                      v-model="form.dob"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.dob)"
                      :tab-index="getOrderIndex(workerFieldNameEnums.dob)"
                      @change="onDobChange"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="yobDatepicker"
                  label-suffix="yearOfBirth"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  label-for="noneBind"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.yob),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.yob)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.yob)"
                >
                  <KValidationProvider
                    name="yob"
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.yob),
                      validateDateFormat: true,
                      validateDateIsRequired: isFieldRequired(
                        workerFieldNameEnums.yob
                      ),
                      remote: checkYobAge,
                      dob: true,
                    }"
                    v-slot="{ errors }"
                    slim
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.yob"
                      :options="dobOptions"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.yob)"
                      :tab-index="getOrderIndex(workerFieldNameEnums.yob)"
                      @change="onYobChange"
                    />
                  </KValidationProvider>
                </k-form-group>
                <!-- <k-form-group
                  label-suffix="itemTag"
                  id="itemTagMultiselect"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'itemTagMultiselect',
                      'order'
                    ),
                  }"
                >
                  <KValidationProvider
                    rules="max:200"
                    v-slot="{ errors }"
                    slim
                    name="Item Tag"
                  >
                    <k-alert
                      :show="errors.length > 0"
                      variant="warning"
                    >
                      {{ errors[0] }}
                    </k-alert>
                    <k-multiselect
                      id="tag"
                      ref="multiselect"
                      :options="tagList"
                      :taggable="true"
                      :add-tag="addTag"
                      v-model="form.selectedTagList"
                      :aria-label="$g('tag')"
                    />
                  </KValidationProvider>
                </k-form-group> -->
                <k-form-group
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.electionInspector
                    ),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.electionInspector)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    showElectionInspector &&
                    isFieldShow(workerFieldNameEnums.electionInspector)
                  "
                  label-suffix="electionInspector"
                  label-for="electionInspector"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.electionInspector
                      ),
                    }"
                    v-slot="{ errors }"
                    slim
                    name="activate"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.electionInspector"
                      :options="yesNoOptions"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.electionInspector)
                      "
                      @change="onChangeElectionInspector"
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.electionInspector)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="partySelect"
                  label-suffix="party"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  label-for="noneBind"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.partyId),
                  }"
                  :label-class="
                    politicalPartyRequired || form.electionInspector == 2
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isShowPartyId"
                >
                  <KValidationProvider
                    :rules="{
                      required:
                        politicalPartyRequired || form.electionInspector == 2,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.politicalPartyId"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.partyId)"
                      :options="partys"
                      :tab-index="getOrderIndex(workerFieldNameEnums.partyId)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <b-form-group
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.ssn),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.ssn)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.ssn)"
                  label-suffix="sSN"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  id="ssnTooltip"
                >
                  <template #label>
                    <span
                      :class="
                        getConfigVal(
                          curPageConfigAndDefaultPageConfig,
                          'ssnTooltip',
                          'labelClass'
                        )
                      "
                      style="margin-right: 6px"
                      >{{ $g("sSN") }}:
                    </span>
                    <b-icon
                      icon="info-circle-fill"
                      v-if="
                        getConfigVal(
                          curPageConfigAndDefaultPageConfig,
                          'ssnTooltipIcon',
                          'vIf'
                        )
                      "
                      v-b-tooltip="{
                        placement: 'righttop',
                        title: $g('SSNDescription'),
                        boundary: 'window',
                        container: 'ssnTooltip',
                      }"
                    />
                  </template>
                  <KValidationProvider
                    :rules="
                      ssnDigits == 9
                        ? {
                            required:
                              isFieldRequired(workerFieldNameEnums.ssn) &&
                              !isShowPassword
                                ? true
                                : false,
                            ssnLength: 9,
                            ssn: {
                              firstBanNumber: ['000', '666'],
                              firstBanNumberBetween: [900, 999],
                              middleNumberBetween: [0, 100],
                              lastnNumberBetween: [0, 10000],
                            },
                            remote: isSSNUsed,
                          }
                        : {
                            required:
                              isFieldRequired(workerFieldNameEnums.ssn) &&
                              !isShowPassword
                                ? true
                                : false,
                            ssnLength_4: {
                              numberBetween: [0, 10000],
                            },
                          }
                    "
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <KSSN
                      v-model="form.ssn"
                      :ssn-digits="ssnDigits"
                      :props-show-password="true"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.ssn)"
                      :tab-index="getOrderIndex(workerFieldNameEnums.ssn)"
                    />
                  </KValidationProvider>
                </b-form-group>
                <k-form-group
                  id="raceSelect"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.raceId),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.raceId)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.raceId)"
                  label-suffix="whatsYourRace"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  label-for="noneBind"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.raceId),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.raceId"
                      :options="raceOptions"
                      :plaintext="isFieldViewOnly(workerFieldNameEnums.raceId)"
                      :tab-index="getOrderIndex(workerFieldNameEnums.raceId)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <div
                  class="cardHead"
                  v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.residentialAddress
                    ),
                  }"
                >
                  {{ $g("residentialAddress") }}
                  <div
                    v-if="needAddressDescription"
                    id="residentialAddressDiv"
                    class="addressDescription"
                  >
                    {{ $g("residentialAddressDescription") }}
                  </div>
                </div>
                <address-form
                  id="residentialAddress"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.residentialAddress
                    ),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
                  identifier="residential"
                  address-label="residentialAddress"
                  :address="residentialAddressForm"
                  :street-types="streetTypes"
                  :state-types="stateTypes"
                  :street-posts="streetPosts"
                  :loading="loading"
                  :addressformat="addressformat"
                  :validate="true"
                  :is-show-overseas-address="false"
                  overseas-validate
                  class="cardBody addressHandle"
                  @addressNativeChanged="addressNativeChanged"
                  street-name-placeholder="application.streetNamePlaceholder"
                  :is-plain-text="
                    isFieldViewOnly(workerFieldNameEnums.residentialAddress)
                  "
                />
                <div
                  class="cardHead"
                  v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.postalAddress),
                  }"
                >
                  {{ $g("postalAddress") }}
                  <div
                    v-if="needAddressDescription"
                    id="postalAddressDiv"
                    class="addressDescription"
                  >
                    {{ $g("postalAddressDescription1") }}
                    {{ $g("postalAddressDescription2") }}
                  </div>
                </div>
                <address-form-overseas
                  id="postalAddress"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.postalAddress),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
                  :is-plain-text="
                    isFieldViewOnly(workerFieldNameEnums.postalAddress)
                  "
                  ref="addressFormOverseas"
                  identifier="postal"
                  address-label="postalAddress"
                  :address="postalAddressForm"
                  :loading="loading"
                  address-copy-label="sara"
                  :address-to-copy="residentialAddressForm"
                  :overseas-validate="false"
                  :mailing-address-format="mailingaddressformat"
                  :has-oversea-country="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'addressFormOverseasOverseaCountry',
                      'vIf'
                    ) &&
                    mailingaddressformat.find(
                      (it) => it.name === 'AddressLine4'
                    ) != null
                  "
                  class="cardBody addressHandle"
                />
                <div
                  class="cardHead"
                  v-if="!isHideStudentFields"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.languageIdList),
                  }"
                >
                  {{ $g("applicationDetails") }}
                </div>
                <k-form-group
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.languageIdList),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.languageIdList)"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.languageIdList)
                      ? 'require-mark'
                      : ''
                  "
                  id="languageSpokenMultiselect"
                  label-suffix="languageSpoken"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.languageIdList
                      ),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-multiselect
                      :options="languageList"
                      v-model="form.languageIdList"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.languageIdList)
                      "
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.languageIdList)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="travelSelect"
                  :label-suffix="
                    !getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'travelSelect',
                      'vIf'
                    )
                      ? 'travel'
                      : ''
                  "
                  :label="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'travelSelect',
                      'vIf'
                    )
                      ? 'travel'
                      : ''
                  "
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.workTravelId),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.workTravelId)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    isFieldShow(workerFieldNameEnums.workTravelId) &&
                    !isHideTravel
                  "
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.workTravelId
                      ),
                    }"
                    v-slot="{ errors }"
                    slim
                    name="workTravelId"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.workTravelId"
                      :options="travelList"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.workTravelId)
                      "
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.workTravelId)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.asWorkerPastThreeYear
                    ),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.asWorkerPastThreeYear)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.asWorkerPastThreeYear)"
                  label-suffix="pastThreeYears"
                  id="pastThreeYearsRadio"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.asWorkerPastThreeYear
                      ),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <b-form-radio-group
                      :options="yesOrNoOptions"
                      class="mt-lg-2"
                      v-model="form.asWorkerPastThreeYear"
                      :plaintext="
                        isFieldViewOnly(
                          workerFieldNameEnums.asWorkerPastThreeYear
                        )
                      "
                      :tab-index="
                        getOrderIndex(
                          workerFieldNameEnums.asWorkerPastThreeYear
                        )
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="positionPreferenceCheckbox"
                  label-suffix="positionPreference"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.jobIdList),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.jobIdList)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    !isHideStudentFields &&
                    isFieldShow(workerFieldNameEnums.jobIdList)
                  "
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.jobIdList),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-checkbox-group
                      v-model="form.jobIdList"
                      :options="positionPreferenceList"
                      class="col-form-label d-block"
                      :disabled="
                        isFieldViewOnly(workerFieldNameEnums.jobIdList)
                      "
                      :tab-index="getOrderIndex(workerFieldNameEnums.jobIdList)"
                      stacked
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="volunteerRadio"
                  label-suffix="volunteer"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.volunteer),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.volunteer)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.volunteer)"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(workerFieldNameEnums.volunteer),
                    }"
                    v-slot="{ errors }"
                    slim
                    name="volunteer"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-radio-group
                      class="col-form-label d-block"
                      v-model="form.volunteer"
                      :disabled="isVolunteerPlainText"
                      :options="yesOrNoOptions"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.volunteer)
                      "
                      :tab-index="getOrderIndex(workerFieldNameEnums.volunteer)"
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="municipalityMultiselect"
                  label-suffix="Municipality"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.municipalityBoundaryIds
                    ),
                  }"
                  :label-class="
                    isFieldRequired(
                      workerFieldNameEnums.municipalityBoundaryIds
                    )
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    isFieldShow(workerFieldNameEnums.municipalityBoundaryIds)
                  "
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.municipalityBoundaryIds
                      ),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-multiselect
                      :options="MunicipalityBoundaryList"
                      v-model="form.municipalityBoundaryIds"
                      :plaintext="
                        isFieldViewOnly(
                          workerFieldNameEnums.municipalityBoundaryIds
                        )
                      "
                      :tab-index="
                        getOrderIndex(
                          workerFieldNameEnums.municipalityBoundaryIds
                        )
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="hearAboutSelect"
                  :label-suffix="
                    !getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'hearAboutSelect',
                      'vIf'
                    )
                      ? 'hearAbout'
                      : ''
                  "
                  :label="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'hearAboutSelect',
                      'vIf'
                    )
                      ? 'hearAbout'
                      : ''
                  "
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.workerHearAboutId
                    ),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.workerHearAboutId)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    isFieldShow(workerFieldNameEnums.workerHearAboutId) &&
                    !isHideStudentFields
                  "
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.workerHearAboutId
                      ),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-select
                      v-model="form.workerHearAboutId"
                      :options="hearAbouts"
                      @change="changeHearAbout"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.workerHearAboutId)
                      "
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.workerHearAboutId)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  id="otherInput"
                  v-show="showHearAboutText"
                  :label="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'otherInput',
                      'vIf'
                    )
                      ? 'other'
                      : ''
                  "
                  :label-class="showHearAboutText ? 'require-mark' : ''"
                  label-for="noneBind"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.workerHearAboutId
                    ),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.workerHearAboutId)"
                >
                  <KValidationProvider
                    :rules="{
                      required: showHearAboutText,
                      max: 200,
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-input
                      id="workerHearAboutText"
                      v-model="form.workerHearAboutText"
                      :aria-label="$g('workerHearAboutText')"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.workerHearAboutId)
                      "
                      :tab-index="
                        getOrderIndex(workerFieldNameEnums.workerHearAboutId)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  :label-suffix="$route.query.workerTypeDocumentLabel"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                  :no-locale="true"
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.workerTypeUpload),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.workerTypeUpload)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    $route.query.needWorkerTypeDocument == 1 &&
                    isFieldShow(workerFieldNameEnums.workerTypeUpload)
                  "
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.workerTypeUpload
                      ),
                      acceptFileType: accept,
                      maxFileSize: size * 1024,
                    }"
                    v-slot="{ errors }"
                    slim
                    name="workerTypeDocument"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <common-upload
                      v-model="form.workerTypeDocument"
                      :accept="accept"
                      :size="size"
                      :is-multiple="true"
                      :on-delete="onDelete"
                      :placeholder="placeholder"
                      :show-message="false"
                      :is-use-internal-validate="false"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.workerTypeUpload)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.hadESALanguageProficiency
                    ),
                  }"
                  :label-class="
                    isFieldRequired(
                      workerFieldNameEnums.hadESALanguageProficiency
                    )
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    isFieldShow(workerFieldNameEnums.hadESALanguageProficiency)
                  "
                  id="hasCertificationUploadedRadio"
                  label-suffix="haveLanguageProficiencyCertified"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.hadESALanguageProficiency
                      ),
                    }"
                    v-slot="{ errors }"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <k-form-radio-group
                      class="col-form-label d-block"
                      v-model="form.hadESALanguageProficiency"
                      :options="yesOrNoOptions"
                      @change="hadESALanguageProficiencyChange"
                      :plaintext="
                        isFieldViewOnly(
                          workerFieldNameEnums.hadESALanguageProficiency
                        )
                      "
                      :tab-index="
                        getOrderIndex(
                          workerFieldNameEnums.hadESALanguageProficiency
                        )
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-form-group
                  :style="{
                    order: getOrderIndex(workerFieldNameEnums.certificate),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.certificate)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="
                    isFieldShow(workerFieldNameEnums.certificate) &&
                    form.hadESALanguageProficiency == 2
                  "
                  id="certifiedByText"
                  label-suffix="certificate"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.certificate
                      ),
                      acceptFileType: docAccept,
                      maxFileSize: docSize * 1024,
                    }"
                    v-slot="{ errors }"
                    slim
                    name="workerTypeDocument"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <common-upload
                      v-model="form.certificateDocument"
                      :accept="docAccept"
                      :size="docSize"
                      :is-multiple="true"
                      :on-delete="onDelete"
                      :placeholder="placeholder"
                      :show-message="false"
                      :is-use-internal-validate="false"
                      :plaintext="
                        isFieldViewOnly(workerFieldNameEnums.certificate)
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
                <k-extend-fields
                  v-model="form.workerPreferenceExtFields"
                  ref="workerPreferenceExtFields"
                  :hide-order="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'hideOrder',
                      'vIf'
                    )
                  "
                  v-frag
                />
                <k-extend-fields
                  v-if="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'extendFields',
                      'vIf'
                    )
                  "
                  id="extendFields"
                  v-model="form"
                  ref="extFields"
                  :hide-order="
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      'hideOrder',
                      'vIf'
                    )
                  "
                  v-frag
                />
                <k-extend-fields
                  v-model="form.workerTypeExtFields"
                  ref="workerTypeExtFields"
                  v-frag
                />
                <div
                  class="content-title"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.citizenshipDocuments
                    ),
                  }"
                  v-if="isFieldShow(workerFieldNameEnums.citizenshipDocuments)"
                >
                  {{
                    $g(
                      getConfigVal(
                        curPageConfigAndDefaultPageConfig,
                        "citizenshipDocumentUpload",
                        "title"
                      )
                    )
                  }}
                </div>
                <k-form-group
                  id="citizenshipDocumentUpload"
                  :style="{
                    order: getOrderIndex(
                      workerFieldNameEnums.citizenshipDocuments
                    ),
                  }"
                  :label-class="
                    isFieldRequired(workerFieldNameEnums.citizenshipDocuments)
                      ? 'require-mark'
                      : ''
                  "
                  v-if="isFieldShow(workerFieldNameEnums.citizenshipDocuments)"
                  label-suffix="document.file"
                  label-align-md="right"
                  label-cols-md="3"
                  content-cols-lg="6"
                  content-cols-md="7"
                >
                  <KValidationProvider
                    :rules="{
                      required: isFieldRequired(
                        workerFieldNameEnums.citizenshipDocuments
                      ),
                      acceptFileType: accept,
                      maxFileSize: size * 1024,
                    }"
                    v-slot="{ errors }"
                    slim
                    :name="$g('document.file')"
                  >
                    <k-alert :show="errors.length > 0" variant="warning">
                      {{ errors[0] }}
                    </k-alert>
                    <common-upload
                      v-model="form.citizenshipDocuments"
                      :accept="accept"
                      :size="size"
                      :is-multiple="true"
                      :on-delete="onDelete"
                      :placeholder="placeholder"
                      :show-message="false"
                      :is-use-internal-validate="false"
                      :plaintext="
                        isFieldViewOnly(
                          workerFieldNameEnums.citizenshipDocuments
                        )
                      "
                    />
                  </KValidationProvider>
                </k-form-group>
              </div>
              <k-form-group
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                class="wapBtnCenter"
              >
                <div style="margin-top: 30px">
                  <k-button
                    @click="backAction(0)"
                    variant="utility"
                    @keydown.enter="backAction(0)"
                    class="w-150"
                    style="margin-right: 80px"
                  >
                    back
                  </k-button>
                  <k-button
                    type="submit"
                    variant="primary"
                    @keydown.enter="save"
                    :disabled="isDisable"
                    class="w-150"
                  >
                    next
                  </k-button>
                </div>
              </k-form-group>
            </div>
          </k-form>
        </KValidationObserver>
      </div>
      <NotificationPreferences
        :form="form"
        v-else-if="curIndex === 1"
        @next-action="nextAction"
        @back-action="backAction"
      />
      <TermsAndConditions
        id="termsAndConditions"
        v-model="termsChecked"
        v-else-if="
          getConfigVal(
            curPageConfigAndDefaultPageConfig,
            'termsAndConditions',
            'vIf'
          ) && curIndex === 2
        "
        @next-action="nextAction"
        @back-action="backAction"
      />
      <Preview
        id="preview"
        :form="form"
        v-else-if="
          getConfigVal(curPageConfigAndDefaultPageConfig, 'preview', 'vIf') &&
          getConfigVal(
            curPageConfigAndDefaultPageConfig,
            'termsAndConditions',
            'vIf'
          )
            ? curIndex === 3
            : curIndex === 2
        "
        :propDobOptions="dobOptions"
        @next-action="nextAction"
        @back-action="backAction"
        :political-party-required="politicalPartyRequired"
        :addressformat="addressformat"
        :mailingaddressformat="mailingaddressformat"
        :residential-address-form="residentialAddressForm"
        :postal-address-form="postalAddressForm"
        :municipality-boundary-list="MunicipalityBoundaryList"
        :hear-abouts="hearAbouts"
        :show-hear-about-text="showHearAboutText"
        :need-address-description="needAddressDescription"
        :is-hide-student-fields="isHideStudentFields"
        :prop-dob-options="dobOptions"
        :tag-list="tagList"
        :is-job-code-require="isFieldRequired(workerFieldNameEnums.jobCode)"
        :ssn-digits="ssnDigits"
        :is-hide-travel="isHideTravel"
        :show-election-inspector="showElectionInspector"
      />
    </template>
    <k-popwindow
      id="popCropper"
      @ok="onPopCropper"
      :title="$g('popwindow.photo')"
      :before-close="onBeforeClose"
      class="content-form"
      :check-selected="false"
    >
      <PhotoTailor
        ref="PhotoTailor"
        :request-api="uploadPhotoRequest"
        :getimgurldata="getImgUrlData"
      />
    </k-popwindow>
  </TimeLine>
</template>

<script>
import TimeLine from "./components/timeline";
import TopInfo from "./components/top-info";
import NotificationPreferences from "./components/notification-preferences";
import TermsAndConditions from "./components/terms-and-conditions";
import Preview from "./components/preview";
import { searchVoterResultKey } from "@/utils/storeageKey";
import {
  getAddressFormat,
  getMailingAddressFormat,
  getSuffixList,
} from "@/api/common";
import {
  getWorkerExFields,
  getWorkerTypeExFields,
  getStreetType,
  getStateType,
  getStreetPost,
  getPartys,
  checkWorktypeAge,
  GetMunicipalityBoundary,
  getVolunteerByWorkerTypeId,
  getHearAbout,
  getStudentId,
  getCountyemployeeId,
  getUploadConfig,
  getPreferenceExFields,
  getRaceOptions,
  getSSNSettings,
  getJobCode,
  checkJobCode,
  checkSSNUsed,
  getWorkerProfileSettings,
} from "@/api/application";
import {
  originDataChangeMixin,
  globalEnv,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  isPrevent,
  yobYearUtc,
  KSSN,
} from "fe-infrastractures";
import {
  getPortalPageSetting,
  getWorkerTravelsListV2,
  getPositionPreferenceListV2,
  getPersonLanguageList,
  getYearOfBirthOptions,
} from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import noPhotoPath from "@/assets/img/photo-slices.png";
import http from "fe-infrastractures/src/utils/http";
import { anonGetTags } from "../../api/tags";
import frag from "vue-frag";
import {
  getFieldDisplayName,
  getFieldIsHidden,
  getFieldIsRequired,
  getFieldIsViewOnly,
  getFieldOrderIndex,
  workerFieldNameEnums,
  workerModuleType,
} from "@/utils/enums/workerTypeField";
export default {
  components: {
    TopInfo,
    TimeLine,
    NotificationPreferences,
    TermsAndConditions,
    Preview,
    KSSN,
  },
  mixins: [originDataChangeMixin, yobYearUtc],
  directives: {
    frag,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      dobOptions: [],
      curPageConfigAndDefaultPageConfig,
      isShowPassword: false,
      showHearAboutText: false,
      hearAbouts: [],
      curIndex: 0,
      pageType: pageNameEnum.ApplicationBasicInfo,
      MunicipalityBoundaryList: [],
      residentialAddressForm: {
        isSame: false,
        isOverseas: false,
        unit: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        city: "",
        state: "",
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
        country: "",
        streetFraction: "",
        streetPre: "",
        streetPost: "",
        building: "",
        apartment: "",
      },
      tagList: [],
      form: {
        dob: null,
        yob: null,
        extFields: [],
        workerTypeExtFields: {
          extFields: [],
        },
        workerPreferenceExtFields: {
          extFields: [],
        },
        photoPath: noPhotoPath,
        photo: "",
        suffix: "",
        selectedTagList: [],
        certificateDocument: [],
        hadESALanguageProficiency: 1,
        asWorkerPastThreeYear: 1,
        jobCode: "",
        workerProfileSettings: [],
        electionInspector: null,
      },
      yesNoOptions: [
        { text: "", value: null },
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      isVolunteerPlainText: false,
      addressformat: "",
      mailingaddressformat: [],
      loading: false,
      loadCompeleted: false,
      streetTypes: [],
      streetPosts: [],
      stateTypes: [],
      partys: [],
      raceOptions: [],
      languageList: [],
      travelList: [],
      positionPreferenceList: [],
      postalAddressForm: {
        isSame: false,
        isOverseas: false,
        unit: "",
        streetNumber: "",
        streetName: "",
        streetType: "",
        city: "",
        state: "",
        postcode: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        overseaCountry: "",
        country: "",
        streetFraction: "",
        streetPre: "",
        streetPost: "",
        building: "",
      },
      termsChecked: null,
      needAddressDescription: false,
      studentId: "",
      countyemployeeId: "",
      suffixOptions: [],
      documentFiles: [],
      accept: "",
      size: 0,
      docAccept: "",
      docSize: 0,
      placeholder: "No file chosen",
      isDisable: false,
      politicalPartyRequired: false,
      yesOrNoOptions: [
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      ssnDigits: 9,
      jobCodeAuto: false,
      isHideTravel: false,
      showElectionInspector: false,
      workerFieldNameEnums: workerFieldNameEnums,
      moduleType: workerModuleType.workerPortalApplication,
    };
  },
  computed: {
    isShowPartyId() {
      if (!this.isFieldShow(workerFieldNameEnums.partyId)) {
        return false;
      }
      if (this.isFieldShow(workerFieldNameEnums.electionInspector)) {
        if (this.showElectionInspector) {
          return this.form.electionInspector == 2;
        }
        return true;
      } else {
        return true;
      }
    },
    topTitle() {
      if (this.curIndex === 1) {
        return "notificationPreferences";
      }
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "termsAndConditions",
          "vIf"
        ) &&
        this.curIndex === 2
      ) {
        return "termsAndConditions";
      }
      return null;
    },
    pollWorkerExFieldRadiobox1() {
      return this.getExtField("pollWorkerExFieldRadiobox1");
    },
    pollWorkerExFieldRadiobox2() {
      return this.getExtField("pollWorkerExFieldRadiobox2");
    },
    pollWorkerExFieldStr1() {
      return this.getExtField("pollWorkerExFieldStr1");
    },
    isHideStudentFields() {
      return (
        this.studentId?.toLowerCase() == this.$route.query.id?.toLowerCase() &&
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "hideStudentFields",
          "hide"
        )
      );
    },
  },
  created() {
    this.getSSNSettingsFun();
    this.initDobOptions();
    getSuffixList().then((res) => {
      this.suffixOptions = res.data;
    });
    getPersonLanguageList().then((resp) => {
      this.languageList = resp.data || [];
    });
    GetMunicipalityBoundary().then((r) => {
      this.MunicipalityBoundaryList = r.data;
    });
    getUploadConfig("citizenshipdoc").then((data) => {
      if (data.data) {
        this.accept = data.data.acceptTypes;
        this.size = data.data.maxSize / 1024;
      }
    });
    getUploadConfig("doc").then((data) => {
      if (data.data) {
        this.docAccept = data.data.acceptTypes;
        this.docSize = data.data.maxSize / 1024;
      }
    });
    getStateType({}).then((data) => {
      const items = data.data;
      this.stateTypes = items || [];
      getWorkerExFields().then((r) => {
        this.form = {
          ...this.form,
          ...r.data,
        };
        getWorkerProfileSettings().then((result) => {
          this.form.workerProfileSettings = result.data || [];
          if (
            this.getConfigVal(
              this.curPageConfigAndDefaultPageConfig,
              "pollWorkerExFieldStr1Multiselect",
              "vIf"
            )
          ) {
            this.form.pollWorkerExFieldStr1 = [];
          }
          this.form.workerTypeExtFields = {
            extFields: [],
          };
          const searchVoterRes = sessionStorage.getItem(searchVoterResultKey);
          console.log(searchVoterRes);
          if (searchVoterRes != null) {
            //从选民搜索进来
            const ele = JSON.parse(searchVoterRes);
            let hasState = false;
            this.stateTypes.forEach((item) => {
              if (item.value == ele.residentialState) {
                hasState = true;
              }
            });
            if (!hasState) {
              ele.residentialState = "";
            }
            this.form = {
              ...this.form,
              ...ele,
            };
            this.form.dob = this.form.dobLong;
            this.form.notDisturbTo = this.form.noDisturbHoursEnd;
            this.form.notDisturbFrom = this.form.noDisturbHoursStart;
            this.form.useEmail = this.form.emailPreference;
            this.form.useSms = this.form.smsPreference;
            this.form.usePhone = this.form.phonePreference;
            this.form.notDisturb = this.form.isUseNoDisturbHours;
            this.form.additionalPhoneNumber = "";
            delete this.form.dobLong;
            delete this.form.dobStr;
            delete this.form.noDisturbHoursEnd;
            delete this.form.noDisturbHoursStart;
            delete this.form.isUseNoDisturbHours;

            if (this.residentialAddressDivVIF && this.postalAddressDivVIF) {
              this.needAddressDescription = true;
            }
          } else {
            this.form.useEmail = 2;
            this.form.useSms = 2;
            this.form.usePhone = 2;
            this.form.notDisturb = 1;
            this.form.additionalPhoneNumber = "";
          }
          if (this.form.photoPath == null || this.form.photoPath == "") {
            this.form.photoPath = noPhotoPath;
          }
          this.setFormCloseModel(this.form);
          getWorkerTypeExFields(this.$route.query.id, {
            type: this.moduleType,
          }).then((res) => {
            this.politicalPartyRequired = res.data.partyRequired == 2;
            getVolunteerByWorkerTypeId(this.$route.query.id).then((r) => {
              this.form = {
                ...this.form,
                workerTypeExtFields: res.data,
                ...this.mapResidentialAddressForm(),
                ...this.mapPostalAddressForm(),
              };
              this.form.volunteer = r.data;
              this.isVolunteerPlainText = this.form.volunteer == 2;
              this.form.workerTypeId = this.$route.query.id;
              if (this.volunteerRadioVIF) {
                this.form.volunteer = 1;
              }
              if (
                this.languageSpokenMultiselectVIF &&
                (!this.form.languageIdList ||
                  this.form.languageIdList.length == 0)
              ) {
                this.languageList.forEach((item) => {
                  if (item.isDefault) {
                    this.$set(this.form, "languageIdList", [item.value]);
                  }
                });
              }
              getPreferenceExFields().then((r) => {
                this.form = {
                  ...this.form,
                  workerPreferenceExtFields: r.data,
                };
                this.setFormData(this.form);
                this.$refs.observer.reset();
              });
            });
            (res.data.workerTypeFieldSettings || []).forEach((d) => {
              if (d.fieldName == "electionInspector") {
                this.showElectionInspector = d.isShow;
              }
              if (d.fieldName == "workTravelId") {
                this.isHideTravel = !d.isShow;
              }
            });
          });
        });
      });
    });
    this.setTopInfoDesc();
    getStudentId().then((r) => {
      this.studentId = r.data;
    });
    getCountyemployeeId().then((r) => {
      this.countyemployeeId = r.data;
    });
    getJobCode().then((resp) => {
      this.jobCodeAuto = resp.data.jobCodeAuto;
      this.form.jobCode = resp.data.jobCode;
    });
  },
  watch: {
    curIndex(val) {
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "termsAndConditions",
          "vIf"
        ) &&
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "preview",
          "vIf"
        )
      ) {
        switch (val) {
          case 0:
            this.pageType = pageNameEnum.ApplicationBasicInfo;
            break;
          case 1:
            this.pageType = pageNameEnum.ApplicationNotificationPreferences;
            break;
          case 2:
            this.pageType = pageNameEnum.ApplicationTermsAndConditions;
            break;
          case 3:
            this.pageType = pageNameEnum.ApplicationPreview;
            break;
        }
      } else {
        switch (val) {
          case 0:
            this.pageType = pageNameEnum.ApplicationBasicInfo;
            break;
          case 1:
            this.pageType = pageNameEnum.ApplicationNotificationPreferences;
            break;
          case 2:
            this.pageType = pageNameEnum.ApplicationPreview;
            break;
        }
      }
      this.setTopInfoDesc();
    },
  },
  mounted() {
    anonGetTags().then((data) => {
      this.tagList = data.data;
    });
    getAddressFormat().then((res) => {
      this.addressformat = res.data;
    });
    getMailingAddressFormat().then((res) => {
      this.mailingaddressformat = res.data;
    });
    getStreetType({}).then((data) => {
      const items = data.data;
      this.streetTypes = items || [];
    });
    getStreetPost({}).then((data) => {
      const items = data.data;
      this.streetPosts = items || [];
    });
    getPartys().then((res) => {
      this.partys = res.data;
    });
    getRaceOptions().then((res) => {
      this.raceOptions = res.data;
    });
    getWorkerTravelsListV2().then((resp) => {
      this.travelList = resp.data || [];
    });
    getPositionPreferenceListV2({ workerTypeId: this.$route.query.id }).then(
      (resp) => {
        this.positionPreferenceList = resp.data || [];
      }
    );

    getHearAbout().then((r) => {
      this.hearAbouts = r.data;
    });
  },
  methods: {
    onDobChange(flag) {
      if (
        !this.form.dob ||
        this.form.dob == -2208988800000 ||
        this.form.dob == 0
      ) {
        return;
      }
      if (!flag) {
        var yob = this.dobOptions.find((q) => q.value == this.form.yob)?.value;
        if (yob) {
          return;
        }
      }
      for (let index = 0; index < this.dobOptions.length; index++) {
        var item = this.dobOptions[index];
        if (item.value && this.form.dob <= item.value) {
          if (index == 0 || this.form.dob == item.value) {
            this.form.yob = item.value;
          } else {
            this.form.yob = this.dobOptions[index - 1].value;
          }
          return;
        }
        if (this.dobOptions.length - 1 == index && item.value < this.form.dob) {
          this.form.yob = item.value;
          return;
        }
      }
    },
    onYobChange() {
      var yob = this.dobOptions.find((q) => q.value == this.form.yob)?.value;
      if (!yob) {
        return;
      }
      if (
        this.form.dob &&
        this.form.dob != -2208988800000 &&
        this.form.dob != 0
      ) {
        return;
      }
      this.form.dob = this.form.yob;
    },
    getOrderIndex(fieldName) {
      return getFieldOrderIndex(fieldName, this.form.workerProfileSettings);
    },
    isFieldShow(fieldName) {
      return !getFieldIsHidden(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    getDisplayName(fieldName) {
      return getFieldDisplayName(fieldName, this.form.workerProfileSettings);
    },
    isFieldRequired(fieldName) {
      return getFieldIsRequired(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    isFieldViewOnly(fieldName) {
      return getFieldIsViewOnly(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    onChangeElectionInspector(val) {
      if (val != 2) {
        this.form.politicalPartyId = null;
      }
    },
    async isSSNUsed() {
      let result = null;
      await checkSSNUsed({
        id: "00000000-0000-0000-0000-000000000000",
        name: this.form.ssn,
      }).then((rep) => {
        result = rep;
      });
      return result;
    },
    async isJobCodeUsed() {
      let result = null;
      await checkJobCode({
        id: "00000000-0000-0000-0000-000000000000",
        name: this.form.jobCode,
      }).then((rep) => {
        result = rep;
      });
      return result;
    },
    getSSNSettingsFun() {
      getSSNSettings().then((resp) => {
        this.ssnDigits = resp.data || 9;
      });
    },
    hadESALanguageProficiencyChange() {
      this.form.certificateDocument = [];
    },
    addTag(newTag) {
      let tag = {
        text: newTag,
        value: newTag,
      };
      this.$refs.multiselect.options.push(tag);
      this.form.selectedTagList.push(tag.value);
    },
    getConfigVal,
    initDobOptions() {
      getYearOfBirthOptions().then((result) => {
        this.dobOptions = result.data;
      });
    },
    uploadPhotoRequest(model) {
      return http.post("/anon/docs", model, {
        loading: true,
        baseURL: globalEnv.VUE_APP_STD_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    changePhoto() {
      this.$bvModal.show("popCropper");
    },
    removePhoto() {
      this.form.photoPath = noPhotoPath;
      this.form.photo = "";
      this.$forceUpdate();
    },
    getImgUrlData(val) {
      this.form.photoPath = val.url;
      this.form.photo = val.id;
      this.$forceUpdate();
    },
    onPopCropper() {
      this.$refs.PhotoTailor.finish();
    },
    onBeforeClose() {
      return this.$bvModal
        .msgBoxConfirm(this.$g("const.confirm.save"), {
          title: this.$g("const.confirm.confirm"),
          cancelTitle: this.$g("const.confirm.saveYes"),
          okTitle: this.$g("const.confirm.no"),
          cancelVariant: "primary",
          okVariant: "secondary",
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          return value != null && !value;
        });
    },
    changeHearAbout() {
      for (var item of this.hearAbouts) {
        if (this.form.workerHearAboutId == item.value) {
          this.showHearAboutText = item.relateContent;
        }
      }
    },
    setTopInfoDesc() {
      getPortalPageSetting(this.pageType).then((data) => {
        this.$refs.topInfo.setDesc(data.data.description);
      });
    },
    setFormData(data) {
      this.$nextTick(() => {
        this.setOrignData(data);
      });
    },
    setFormCloseModel(item) {
      const form = this.form;
      Object.keys(form).forEach(function (v, i) {
        if (v == "dob" && (!form.dob || form.dob <= 0)) {
          form[v] = item["dobLong"];
        } else if (v == "partyId" && !form[v]) {
          form[v] = item["politicalPartyId"];
        } else if (v != "status" && v != "id" && item[v] && !form[v]) {
          form[v] = item[v];
        }
      });
      const residentialAddress = this.mapResidentialAddressForm();
      Object.keys(residentialAddress).forEach(function (v, i) {
        if (item[v] && !residentialAddress[v]) {
          residentialAddress[v] = item[v];
        }
      });

      const postalAddress = this.mapPostalAddressForm();
      for (let v of Object.keys(postalAddress)) {
        if (item[v] && !postalAddress[v]) {
          postalAddress[v] = item[v];
        }
      }

      this.form = {
        ...form,
        ...residentialAddress,
        ...postalAddress,
      };
      this.onDobChange(true);
      this.setResidentialAddressForm(this.form);
    },
    mapResidentialAddressForm() {
      return {
        isOverseasAddress: this.residentialAddressForm.isOverseas ?? false,
        residentialUnit: this.residentialAddressForm.unit,
        residentialStreet: this.residentialAddressForm.streetNumber,
        residentialStreetNumber: this.residentialAddressForm.streetNumber,
        residentialStreetName: this.residentialAddressForm.streetName,
        residentialStreetType: this.residentialAddressForm.streetType,
        residentialCity: this.residentialAddressForm.city,
        residentialLocality: this.residentialAddressForm.city,
        residentialState: this.residentialAddressForm.state,
        residentialPostCode: this.residentialAddressForm.postcode,
        mailingAddressLine1: this.residentialAddressForm.addressLine1,
        mailingAddressLine2: this.residentialAddressForm.addressLine2,
        mailingAddressLine3: this.residentialAddressForm.addressLine3,
        mailingCountry: this.residentialAddressForm.overseaCountry,
        residentialCountry: this.residentialAddressForm.country,
        residentialStreetFraction: this.residentialAddressForm.streetFraction,
        residentialStreetPre: this.residentialAddressForm.streetPre,
        residentialStreetPost: this.residentialAddressForm.streetPost,
        residentialBuilding: this.residentialAddressForm.building,
        residentialApartment: this.residentialAddressForm.apartment,
      };
    },
    mapPostalAddressForm() {
      return {
        isSameAsResidentialAddress: this.postalAddressForm.isSame ?? false,
        mailingAddressLine1: this.postalAddressForm.addressLine1,
        mailingAddressLine2: this.postalAddressForm.addressLine2,
        mailingAddressLine3: this.postalAddressForm.addressLine3,
        mailingCountry: this.postalAddressForm.overseaCountry,
      };
    },
    setResidentialAddressForm(data) {
      this.residentialAddressForm = {
        isOverseas: false,
        unit: data.residentialUnit,
        streetNumber: data.residentialStreetNumber,
        streetName: data.residentialStreetName,
        streetType: data.residentialStreetType,
        city: data.residentialLocality || data.city,
        state: data.residentialState,
        postcode: data.residentialPostcode,
        addressLine1: data.mailingAddressLine1,
        addressLine2: data.mailingAddressLine2,
        addressLine3: data.mailingAddressLine3,
        overseaCountry: data.mailingCountry,
        country: data.residentialCountry,
        streetFraction: data.residentialStreetFraction,
        streetPre: data.residentialStreetPre,
        streetPost: data.residentialStreetPost,
        building: data.residentialBuilding,
        apartment: data.residentialApartment,
      };
      this.postalAddressForm = {
        isSame: data.isSameAsResidentialAddress == 2 ? true : false,
        addressLine1: data.mailingAddressLine1,
        addressLine2: data.mailingAddressLine2,
        addressLine3: data.mailingAddressLine3,
        overseaCountry: data.mailingCountry || data.mailingCounty,
      };
      this.postalAddressForm.isSame = getConfigVal(
        this.curPageConfigAndDefaultPageConfig,
        "isSame",
        "vIf"
      );
    },
    nextAction() {
      this.curIndex++;
    },
    backAction(index) {
      if (index === 0) {
        this.$router.go(-1);
      } else {
        this.curIndex--;
      }
    },
    async checkAge() {
      let result = {
        code: 200,
        data: { status: 1, info: true },
        desc: "Successfully",
        warning: "",
        exception: null,
      };
      if (this.form.dob > 0) {
        await checkWorktypeAge({
          workerTypeId: this.$route.query.id,
          dob: this.form.dob,
        }).then(function (rep) {
          if (rep.data) {
            result = {
              code: 200,
              data: { status: 1, info: true },
              desc: "Successfully",
              warning: "",
              exception: null,
            };
          } else {
            result = rep;
          }
        });
      }
      return result;
    },
    async checkYobAge() {
      let result = {
        code: 200,
        data: { status: 1, info: true },
        desc: "Successfully",
        warning: "",
        exception: null,
      };
      if (this.form.yob > 0) {
        await checkWorktypeAge({
          workerTypeId: this.$route.query.id,
          dob: this.form.yob,
        }).then(function (rep) {
          if (rep.data) {
            result = {
              code: 200,
              data: { status: 1, info: true },
              desc: "Successfully",
              warning: "",
              exception: null,
            };
          } else {
            result = rep;
          }
        });
      }
      return result;
    },
    addressNativeChanged() {
      this.form = {
        ...this.form,
        ...this.mapResidentialAddressForm(),
        ...this.mapPostalAddressForm(),
      };
      if (this.postalAddressForm.isSame) {
        this.$refs.addressFormOverseas.onIsSameAddressChanged(true);
      }
    },
    async save() {
      if (
        this.isFieldShow(workerFieldNameEnums.dob) &&
        this.isFieldShow(workerFieldNameEnums.yob)
      ) {
        this.onDobChange();
        this.onYobChange();
      }
      this.isDisable = true;
      setTimeout(() => {
        this.isDisable = false;
      }, 1000);
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        if (
          this.getConfigVal(
            this.curPageConfigAndDefaultPageConfig,
            "checkCountyEmployee",
            "check"
          ) &&
          this.countyemployeeId?.toLowerCase() ==
            this.$route.query.id?.toLowerCase()
        ) {
          let field = this.getWorkerTypeExtField(
            this.getConfigVal(
              this.curPageConfigAndDefaultPageConfig,
              "checkCountyEmployee",
              "checkFieldName"
            )
          );
          if (
            field &&
            field.options[1].value ==
              this.form.workerTypeExtFields[
                this.getConfigVal(
                  this.curPageConfigAndDefaultPageConfig,
                  "checkCountyEmployee",
                  "checkFieldName"
                )
              ]
          ) {
            this.$bvModal.msgBoxOk(this.$g("countyEmployeeError"), {
              centered: true,
            });

            return false;
          }
        }
        this.form.ssn = this.form.ssn?.replaceAll("-", "");
        this.form = {
          ...this.form,
          ...this.mapResidentialAddressForm(),
          ...this.mapPostalAddressForm(),
        };
        this.nextAction();
      }
    },
    getExtField(fieldName) {
      var result = null;
      if (this.form.extFields) {
        this.form.extFields.forEach((item) => {
          if (item.fieldName == fieldName) {
            result = { ...item };
          }
        });
      }
      if (
        (fieldName == "pollWorkerExFieldRadiobox1" ||
          fieldName == "pollWorkerExFieldRadiobox2") &&
        (this.pollWorkerExFieldRadiobox2SelectVIF ||
          this.pollWorkerExFieldRadiobox1SelectVIF) &&
        result &&
        result.options &&
        result.options[0]?.value
      ) {
        result?.options.unshift({ text: "", value: null });
      }
      return result;
    },
    handleplaintext(item) {
      if (item.hasOwnProperty("isOnlyView") && item.isOnlyView) {
        return true;
      } else {
        return this.plaintext;
      }
    },
    getWorkerTypeExtField(fieldName) {
      var result = null;
      if (this.form.workerTypeExtFields?.extFields) {
        this.form.workerTypeExtFields.extFields.forEach((item) => {
          if (item.fieldName == fieldName) {
            result = { ...item };
          }
        });
      }
      return result;
    },
    onDelete(file) {},
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .addressHandle {
    > div.form-row.form-group {
      > label.col-md-3 {
        display: none;
      }

      > div[class*="col-"] {
        flex: 0 0 100%;
        max-width: 100%;

        .space-between {
          min-height: calc(1.5em + 0.75rem + 2px);
        }
      }
    }
  }

  .addressDescription {
    font-size: 14px;
    font-weight: 400;
  }

  #ssnTooltip label span {
    margin-right: 6px;
  }

  @media (min-width: 768px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 58.333333%;
          max-width: 58.333333%;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .addressHandle {
      > div.form-row.form-group {
        .text-md-left {
          text-align: right !important;
        }

        .col {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    #ssnTooltip .tooltip-inner {
      max-width: none;
    }
  }
}
</style>
