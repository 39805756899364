<template>
  <div>
    <div class="layoutContent">
      <k-alert-msg ref="lblMsg" />
      <KValidationObserver ref="observer">
        <k-form @keydown.enter="isPrevent" @submit.prevent="save">
          <div class="layoutCard">
            <div class="cardBody" style="display: flex; flex-direction: column">
              <div class="cardHead">
                {{ $g("personalData") }}
              </div>
              <k-form-group
                id="photoImg"
                label-suffix="photo"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.photo),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.photo)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.photo)"
              >
                <div class="d-flex flex-wrap justify-content-between">
                  <div class="mr-2">
                    <k-img :src="form.photoPath" style="max-height: 100px" />
                  </div>
                </div>
              </k-form-group>
              <k-form-group
                label-suffix="jobCode"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :label-class="isJobCodeRequire ? 'require-mark' : ''"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.jobCode),
                }"
                v-if="isFieldShow(workerFieldNameEnums.jobCode)"
              >
                <k-form-input v-model="form.jobCode" :plaintext="true" />
              </k-form-group>
              <k-form-group
                id="firstNameInput"
                label-suffix="firstName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.firstName),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.firstName)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.firstName)"
              >
                <k-form-input
                  id="firstName"
                  v-model="form.firstName"
                  :plaintext="true"
                  :aria-label="$g('firstName')"
                />
              </k-form-group>
              <k-form-group
                id="middleNameInput"
                label-suffix="middleName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.middleName),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.middleName)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.middleName)"
              >
                <k-form-input
                  id="middleName"
                  v-model="form.middleName"
                  :aria-label="$g('middleName')"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                id="lastNameInput"
                label-suffix="lastName"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.lastName),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.lastName)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.lastName)"
              >
                <k-form-input
                  id="lastName"
                  v-model="form.lastName"
                  :aria-label="$g('lastName')"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.suffix),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.suffix)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.suffix)"
                label-suffix="Suffix"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-select
                  v-model="form.suffix"
                  :plaintext="true"
                  :options="suffixOptions"
                />
              </k-form-group>
              <k-form-group
                id="dobDatepicker"
                label-suffix="dob"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.dob),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.dob)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.dob)"
              >
                <k-form-datepicker
                  v-model="form.dob"
                  :plaintext="true"
                  :tab-index="getOrderIndex(workerFieldNameEnums.dob)"
                />
              </k-form-group>
              <k-form-group
                id="yobDatepicker"
                label-suffix="yearOfBirth"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.yob),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.yob)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.yob)"
              >
                <k-form-select
                  v-model="form.yob"
                  :options="dobOptions"
                  :plaintext="true"
                  :tab-index="getOrderIndex(workerFieldNameEnums.yob)"
                />
              </k-form-group>
              <!-- <k-form-group
                label-suffix="itemTag"
                id="itemTagMultiselect"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'itemTagMultiselect',
                    'order'
                  ),
                }"
              >
                <k-multiselect
                  id="tag"
                  ref="multiselect"
                  :options="tagList"
                  :taggable="true"
                  v-model="form.selectedTagList"
                  :aria-label="$g('tag')"
                  :plaintext="true"
                />
              </k-form-group> -->
              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.electionInspector),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.electionInspector)
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  showElectionInspector &&
                  isFieldShow(workerFieldNameEnums.electionInspector)
                "
                label-suffix="electionInspector"
                label-for="electionInspector"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-select
                  v-model="form.electionInspector"
                  :plaintext="true"
                  :options="yesNoOptions"
                />
              </k-form-group>
              <k-form-group
                id="partySelect"
                label-suffix="party"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.partyId),
                }"
                :label-class="
                  politicalPartyRequired || form.electionInspector == 2
                    ? 'require-mark'
                    : ''
                "
                v-if="isShowPartyId"
              >
                <k-form-select
                  v-model="form.politicalPartyId"
                  :options="partys"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.ssn),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.ssn)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.ssn)"
                label-suffix="sSN"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                id="ssnTooltip"
              >
                <b-input-group class="align-items-center">
                  <div
                    class="col-form-label"
                    style="min-width: 120px; user-select: none"
                  >
                    {{ isShowPassword ? showSSN : "* * * * * * * * *" }}
                  </div>
                  <b-icon
                    :icon="isShowPassword ? 'eye-fill' : 'eye-slash-fill'"
                    @click="isShowPassword = !isShowPassword"
                    class="iconEye"
                  />
                </b-input-group>
              </k-form-group>
              <k-form-group
                id="raceSelect"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.raceId),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.raceId)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.raceId)"
                label-suffix="whatsYourRace"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                label-for="noneBind"
              >
                <k-form-select
                  v-model="form.raceId"
                  :options="raceOptions"
                  :plaintext="true"
                />
              </k-form-group>
              <div
                class="cardHead"
                v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.residentialAddress),
                }"
              >
                {{ $g("residentialAddress") }}
                <div
                  id="residentialAddressDiv"
                  v-if="needAddressDescription"
                  class="addressDescription"
                >
                  {{ $g("residentialAddressDescription") }}
                </div>
              </div>
              <k-form-group
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.residentialAddress),
                }"
                v-if="isFieldShow(workerFieldNameEnums.residentialAddress)"
              >
                <address-view
                  id="residentialAddress"
                  :address="residentialAddressForm"
                  :addressformat="addressformat"
                  :address-type="1"
                  class="cardBody"
                />
              </k-form-group>
              <div
                class="cardHead"
                v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.postalAddress),
                }"
              >
                {{ $g("postalAddress") }}
                <div
                  id="postalAddressDiv"
                  v-if="needAddressDescription"
                  class="addressDescription"
                >
                  {{ $g("postalAddressDescription1") }}
                  {{ $g("postalAddressDescription2") }}
                </div>
              </div>
              <k-form-group
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.postalAddress),
                }"
                v-if="isFieldShow(workerFieldNameEnums.postalAddress)"
              >
                <address-view
                  id="postalAddress"
                  :address="postalAddressForm"
                  :addressformat="addressformat"
                  :address-to-copy="residentialAddressForm"
                  :address-type="2"
                  :is-copy="form.isSameAsResidentialAddress"
                  class="cardBody"
                />
              </k-form-group>
              <div
                class="cardHead"
                v-if="!isHideStudentFields"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.languageIdList),
                }"
              >
                {{ $g("applicationDetails") }}
              </div>
              <k-form-group
                id="languageSpokenMultiselect"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.languageIdList),
                }"
                v-if="isFieldShow(workerFieldNameEnums.languageIdList)"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.languageIdList)
                    ? 'require-mark'
                    : ''
                "
                label-suffix="languageSpoken"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-multiselect
                  :options="languageList"
                  v-model="form.languageIdList"
                  :plaintext="true"
                />
              </k-form-group>

              <k-form-group
                id="travelSelect"
                :label-suffix="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'travelSelect',
                    'vIf'
                  )
                    ? 'travel'
                    : ''
                "
                :label="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'travelSelect',
                    'vIf'
                  )
                    ? 'travel'
                    : ''
                "
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.workTravelId),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.workTravelId)
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  isFieldShow(workerFieldNameEnums.workTravelId) &&
                  !isHideTravel
                "
              >
                <k-form-select
                  v-model="form.workTravelId"
                  :options="travelList"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                :style="{
                  order: getOrderIndex(
                    workerFieldNameEnums.asWorkerPastThreeYear
                  ),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.asWorkerPastThreeYear)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.asWorkerPastThreeYear)"
                label-suffix="pastThreeYears"
                id="pastThreeYearsRadio"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <b-form-radio-group
                  :disabled="true"
                  :options="yesOrNoOptions"
                  class="mt-lg-2"
                  v-model="form.asWorkerPastThreeYear"
                />
              </k-form-group>
              <k-form-group
                id="positionPreferenceCheckbox"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.jobIdList),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.jobIdList)
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  !isHideStudentFields &&
                  isFieldShow(workerFieldNameEnums.jobIdList)
                "
                label-suffix="positionPreference"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-checkbox-group
                  v-model="form.jobIdList"
                  :options="positionPreferenceList"
                  class="col-form-label d-block"
                  :plaintext="true"
                  stacked
                />
              </k-form-group>
              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.volunteer),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.volunteer)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.volunteer)"
                id="volunteerRadio"
                label-suffix="volunteer"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-radio-group
                  class="col-form-label d-block"
                  v-model="form.volunteer"
                  :disabled="true"
                  :options="yesOrNoOptions"
                />
              </k-form-group>
              <k-form-group
                id="municipalityMultiselect"
                label-suffix="Municipality"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(
                    workerFieldNameEnums.municipalityBoundaryIds
                  ),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.municipalityBoundaryIds)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.municipalityBoundaryIds)"
              >
                <k-multiselect
                  :options="municipalityBoundaryList"
                  v-model="form.municipalityBoundaryIds"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                id="hearAboutSelect"
                :label-suffix="
                  !getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'hearAboutSelect',
                    'vIf'
                  )
                    ? 'hearAbout'
                    : ''
                "
                :label="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'hearAboutSelect',
                    'vIf'
                  )
                    ? 'hearAbout'
                    : ''
                "
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.workerHearAboutId),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.workerHearAboutId)
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  isFieldShow(workerFieldNameEnums.workerHearAboutId) &&
                  !isHideStudentFields
                "
              >
                <k-form-select
                  v-model="form.workerHearAboutId"
                  :options="hearAbouts"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                id="otherInput"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.workerHearAboutId),
                }"
                v-if="isFieldShow(workerFieldNameEnums.workerHearAboutId)"
                v-show="showHearAboutText"
                :label="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'otherInput',
                    'vIf'
                  )
                    ? 'other'
                    : ''
                "
                :label-class="showHearAboutText ? 'require-mark' : ''"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-input
                  v-model="form.workerHearAboutText"
                  :plaintext="true"
                />
              </k-form-group>
              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.workerTypeUpload),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.workerTypeUpload)
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  $route.query.needWorkerTypeDocument == 1 &&
                  isFieldShow(workerFieldNameEnums.workerTypeUpload)
                "
                :label-suffix="$route.query.workerTypeDocumentLabel"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <b-list-group-item
                  v-for="(item, index) in form.workerTypeDocument"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </b-list-group-item>
              </k-form-group>

              <k-form-group
                :style="{
                  order: getOrderIndex(
                    workerFieldNameEnums.hadESALanguageProficiency
                  ),
                }"
                :label-class="
                  isFieldRequired(
                    workerFieldNameEnums.hadESALanguageProficiency
                  )
                    ? 'require-mark'
                    : ''
                "
                v-if="
                  isFieldShow(workerFieldNameEnums.hadESALanguageProficiency)
                "
                id="hasCertificationUploadedRadio"
                label-suffix="haveLanguageProficiencyCertified"
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <k-form-radio-group
                  class="col-form-label d-block"
                  v-model="form.hadESALanguageProficiency"
                  :disabled="true"
                  :options="yesOrNoOptions"
                />
              </k-form-group>

              <k-form-group
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.certifiedBy),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.certifiedBy)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.certifiedBy)"
                id="certifiedByText"
                label-suffix="certificate"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <b-list-group-item
                  v-for="(item, index) in form.certificateDocument"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </b-list-group-item>
              </k-form-group>
              <k-extend-fields
                v-model="form.workerPreferenceExtFields"
                ref="workerPreferenceExtFields"
                :plaintext="true"
                v-frag
              />
              <k-extend-fields
                v-if="
                  getConfigVal(
                    curPageConfigAndDefaultPageConfig,
                    'extendFields',
                    'vIf'
                  )
                "
                id="extendFields"
                v-model="form"
                ref="extFields"
                :plaintext="true"
                v-frag
              />
              <k-extend-fields
                v-model="form.workerTypeExtFields"
                :plaintext="true"
                v-frag
              />
              <div
                class="content-title"
                :style="{
                  order: getOrderIndex(
                    workerFieldNameEnums.citizenshipDocuments
                  ),
                }"
                v-if="isFieldShow(workerFieldNameEnums.citizenshipDocuments)"
              >
                {{
                  $g(
                    getConfigVal(
                      curPageConfigAndDefaultPageConfig,
                      "citizenshipDocumentUpload",
                      "title"
                    )
                  )
                }}
              </div>
              <k-form-group
                :style="{
                  order: getOrderIndex(
                    workerFieldNameEnums.citizenshipDocuments
                  ),
                }"
                :label-class="
                  isFieldRequired(workerFieldNameEnums.citizenshipDocuments)
                    ? 'require-mark'
                    : ''
                "
                v-if="isFieldShow(workerFieldNameEnums.citizenshipDocuments)"
                label-suffix="document.file"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <b-list-group-item
                  v-for="(item, index) in form.citizenshipDocuments"
                  :key="index"
                  class="d-flex justify-content-between align-items-center"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </b-list-group-item>
              </k-form-group>
              <div
                class="cardHead"
                :style="{
                  order: getOrderIndex(workerFieldNameEnums.languageId),
                }"
              >
                {{ $g("notificationPreferences") }}
              </div>
              <NotificationPreferences v-model="form" class="cardBody" v-frag />
            </div>
            <div
              class="cardHead"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'termsAndConditions',
                  'vIf'
                )
              "
              id="termsAndConditions"
            >
              {{ $g("termsAndConditions") }}
            </div>
            <div
              class="cardBody"
              v-if="
                getConfigVal(
                  curPageConfigAndDefaultPageConfig,
                  'agreeContentCheckbox',
                  'vIf'
                )
              "
              id="agreeContentCheckbox"
            >
              <k-form-group
                label-for="noneBind"
                label-align-md="right"
                label-cols-md="3"
                content-cols-lg="6"
                content-cols-md="7"
              >
                <div class="mb-3" v-html="desc" />
                <k-form-checkbox :disabled="true" v-model="checked">
                  agreeContent
                </k-form-checkbox>
              </k-form-group>
            </div>
          </div>
          <k-form-group
            v-if="reCaptchaVif"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div class="alert alert-warning" v-if="isManMachine">
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>

          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
            class="wapBtnCenter"
          >
            <div style="margin-top: 30px">
              <k-button
                @click="backAction"
                variant="utility"
                @keydown.enter="backAction"
                class="w-150"
                style="margin-right: 80px"
              >
                back
              </k-button>
              <k-button
                @click="submit"
                variant="primary"
                class="w-150"
                @keydown.enter="submit"
              >
                submit
              </k-button>
            </div>
          </k-form-group>
        </k-form>
      </KValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  getPartys,
  regWorker,
  multiUploadFile,
  searchVoterRequired,
  getRaceOptions,
} from "@/api/application";
import NotificationPreferences from "./components/notificationPreferences";
import AddressView from "./components/address-view";
import {
  repResult,
  ReCaptcha,
  storeage,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  isPrevent,
} from "fe-infrastractures";
import {
  getPortalPageSetting,
  getWorkerTravelsListV2,
  getPositionPreferenceListV2,
  getPersonLanguageList,
} from "@/api/home";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import { getSuffixList } from "@/api/common";
import frag from "vue-frag";
import {
  getFieldDisplayName,
  getFieldIsHidden,
  getFieldIsRequired,
  getFieldIsViewOnly,
  getFieldOrderIndex,
  workerFieldNameEnums,
  workerModuleType,
} from "@/utils/enums/workerTypeField";

export default {
  directives: {
    frag,
  },
  components: {
    NotificationPreferences,
    AddressView,
    ReCaptcha,
  },
  props: {
    form: {
      type: Object,
      default() {
        return null;
      },
    },
    addressformat: {
      type: String,
      default() {
        return "";
      },
    },
    mailingaddressformat: {
      type: Array,
      default() {
        return [];
      },
    },
    residentialAddressForm: {
      type: Object,
      default() {
        return {};
      },
    },
    postalAddressForm: {
      type: Object,
      default() {
        return {};
      },
    },
    municipalityBoundaryList: {
      type: Array,
      default() {
        return [];
      },
    },
    hearAbouts: {
      type: Array,
      default() {
        return [];
      },
    },
    showHearAboutText: {
      type: Boolean,
      default() {
        return false;
      },
    },
    needAddressDescription: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isHideStudentFields: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isHideTravel: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showElectionInspector: {
      type: Boolean,
      default() {
        return false;
      },
    },
    propDobOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    tagList: {
      type: Array,
      default() {
        return [];
      },
    },
    politicalPartyRequired: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isJobCodeRequire: {
      type: Boolean,
      default() {
        return false;
      },
    },
    ssnDigits: {
      type: Number,
      default() {
        return 9;
      },
    },
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      isPrevent,
      dobOptions: this.propDobOptions,
      curPageConfigAndDefaultPageConfig,
      isShowPassword: false,
      desc: "",
      partys: [],
      raceOptions: [],
      languageList: [],
      travelList: [],
      positionPreferenceList: [],
      isValidateRecaptcha: false,
      isManMachine: false,
      checked: true,
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
      suffixOptions: [],
      docType: 40008,
      logType: 70030,
      reCaptchaVif: true,
      yesNoOptions: [
        { text: "", value: null },
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      yesOrNoOptions: [
        { text: this.$g("yes"), value: 2 },
        { text: this.$g("no"), value: 1 },
      ],
      workerFieldNameEnums: workerFieldNameEnums,
      moduleType: workerModuleType.workerPortalApplication,
    };
  },
  computed: {
    isShowPartyId() {
      if (!this.isFieldShow(workerFieldNameEnums.partyId)) {
        return false;
      }
      if (this.isFieldShow(workerFieldNameEnums.electionInspector)) {
        return this.form.electionInspector == 2 && this.showElectionInspector;
      } else {
        return true;
      }
    },
    pollWorkerExFieldRadiobox1() {
      return this.getExtField("pollWorkerExFieldRadiobox1");
    },
    pollWorkerExFieldRadiobox2() {
      return this.getExtField("pollWorkerExFieldRadiobox2");
    },
    pollWorkerExFieldStr1() {
      return this.getExtField("pollWorkerExFieldStr1");
    },
    showSSN() {
      if (this.form.ssn && this.ssnDigits == 9) {
        return `${this.form.ssn.slice(0, 3)}-${this.form.ssn.slice(
          3,
          5
        )}-${this.form.ssn.slice(5)}`;
      }
      return this.form.ssn ?? "";
    },
  },
  created() {
    getRaceOptions().then((res) => {
      this.raceOptions = res.data;
    });
    getPartys().then((res) => {
      this.partys = res.data;
    });
    getPortalPageSetting(pageNameEnum.ApplicationPreview).then((data) => {
      this.desc = data.data.description;
    });
    getPersonLanguageList().then((resp) => {
      this.languageList = resp.data || [];
    });
    getWorkerTravelsListV2().then((resp) => {
      this.travelList = resp.data || [];
    });
    getPositionPreferenceListV2().then((resp) => {
      this.positionPreferenceList = resp.data || [];
    });
    getSuffixList().then((res) => {
      this.suffixOptions = res.data;
    });
    searchVoterRequired(this.$route.query.id).then((res) => {
      if (res.data == 2) {
        this.reCaptchaVif = false;
      }
    });
  },
  methods: {
    getConfigVal,
    getOrderIndex(fieldName) {
      return getFieldOrderIndex(fieldName, this.form.workerProfileSettings);
    },
    isFieldShow(fieldName) {
      return !getFieldIsHidden(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    getDisplayName(fieldName) {
      return getFieldDisplayName(fieldName, this.form.workerProfileSettings);
    },
    isFieldRequired(fieldName) {
      return getFieldIsRequired(
        fieldName,
        this.form.workerProfileSettings,
        this.moduleType
      );
    },
    backAction() {
      this.$emit("back-action", 3);
    },
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    async reg() {
      this.form.citizenshipFileNames = [];
      this.form.citizenshipDocuments?.forEach((element) => {
        this.form.citizenshipFileNames.push(element.name);
      });
      let form = { ...this.form };
      if (
        this.getConfigVal(
          this.curPageConfigAndDefaultPageConfig,
          "pollWorkerExFieldStr1Multiselect",
          "vIf"
        )
      ) {
        if (form.pollWorkerExFieldStr1) {
          form.pollWorkerExFieldStr1 = JSON.stringify(
            form.pollWorkerExFieldStr1
          );
        }
        if (form.email) {
          form.useEmail = 2;
        }
      }
      let m = await regWorker(form);
      if (m.data.status === repResult.success) {
        if (
          this.form.citizenshipDocuments &&
          this.form.citizenshipDocuments.length
        ) {
          await this.uploadDocumentsFile(
            m.data.info,
            this.form.citizenshipDocuments,
            null
          );
        }
        if (
          this.form.workerTypeDocument &&
          this.form.workerTypeDocument.length
        ) {
          await this.uploadDocumentsFile(
            m.data.info,
            this.form.workerTypeDocument,
            this.$route.query.workerTypeUploadId,
            40004,
            70003
          );
        }
        if (
          this.form.certificateDocument &&
          this.form.certificateDocument.length
        ) {
          await this.uploadDocumentsFile(
            m.data.info,
            this.form.certificateDocument,
            null,
            40011,
            70044
          );
        }
        this.$refs.lblMsg.message(
          m.data.status,
          m.message ? m.message : "Saved Successfully."
        );
        this.$router.push({
          name: "submitSuccessfully",
        });
      } else {
        this.$refs.lblMsg.message(m.data.status, m.data.info);
      }
    },
    submit() {
      var isValid = true;
      if (this.localEnv === false && this.reCaptchaVif) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = this.isValidateRecaptcha;
      }
      if (isValid) {
        let pmOpt = this.$confirm({
          title: this.$g("const.confirm.confirm"),
          content: this.$g("confirm.save"),
          cancelType: "secondary",
          okText: this.$g("const.confirm.yes"),
          cancelText: this.$g("const.confirm.no"),
        });
        if (pmOpt == null) {
          return;
        }
        pmOpt.then(() => {
          this.reg();
        });
      }
    },

    getExtField(fieldName) {
      var result = null;
      if (this.form && this.form.extFields) {
        this.form.extFields.forEach((item) => {
          if (item.fieldName == fieldName) {
            result = { ...item };
          }
        });
      }
      if (
        (fieldName == "pollWorkerExFieldRadiobox1" ||
          fieldName == "pollWorkerExFieldRadiobox2") &&
        (this.pollWorkerExFieldRadiobox2SelectVIF ||
          this.pollWorkerExFieldRadiobox1SelectVIF) &&
        result &&
        result.options &&
        result.options[0]?.value
      ) {
        result?.options.unshift({ text: "", value: null });
      }
      return result;
    },
    uploadDocumentsFile(
      id,
      files,
      RefId2,
      docType = this.docType,
      logType = this.logType
    ) {
      if (files.length) {
        let formData = new FormData();
        formData.append("RefId", id);
        if (RefId2) {
          formData.append("RefId2", RefId2);
        }
        formData.append("DocRequestType", docType);
        formData.append("LogRequestType", logType);
        files.forEach((element) => {
          formData.append(`Files`, element);
        });
        return multiUploadFile(formData);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .layoutCard {
    .cardBody {
      margin-bottom: 30px;
    }
  }

  .iconEye:hover {
    color: var(--featuredFont);
    user-select: none;
  }

  .addressDescription {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
